import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { SignUp } from 'src/app/models/sigup';
import { AuthService } from 'src/app/auth/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MonthlyReportDetails } from 'src/app/models/monthlyReportDetail';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Table } from 'pdfmake/interfaces';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.css'],
})
export class MonthlyReportComponent implements OnInit {
  userData: SignUp;
  monthList = [];
  seletedMonth = null;
  seletedModel=null;
  showButton = false;
  AllReportDetails: MonthlyReportDetails[];
  hasReportData = false;
  totalConsumableObj = {};
  averageComplianceObj = {};
  totalSwipesObj = {};
  totalWaterSavedObj = {};
  //Chart Variables
  totalSwipeReport: any;
  averageComplianceReport: any;
  totalWaterSavedReport: any;
  totalConsumableReport: any;
  //Chart DataURL variables
  totalSwipeChartDataURL: any;
  averageComplianceChartDataURL: any;
  totalWaterSavedChartDataURL: any;
  totalConsumableChartDataURL: any;

  historyDataResult:any;

  tw=0;
  tw1=0;
  tw2=0;
  tw3=0;
  td1=0;
  td2=0;
  constructor(
    private commonService: CommonService,
    private chRef: ChangeDetectorRef,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.userData = new SignUp();
  }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.getMonthList();
    this.chRef.detectChanges();
  }

  onMonthSelected(event) {
    this.seletedMonth = event.target.value;
   // this.showButton = this.seletedMonth ? true : false;
  }
  onModelSelected(event) {
    this.seletedModel = event.target.value;
    this.showButton = this.seletedMonth ? true : false;
  }

  getMonthList() {
    this.commonService.getMonthList(this.userData.UId).subscribe({
      next: (res) => {
        const { monthList } = res;
        this.monthList = monthList;
      },
      error: (err) => {
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: err?.error?.message,
        });
      },
    });
  }
  getReportList() {
    this.spinner.show();
    this.commonService
      .getMonthlyReports(this.userData.UId, this.seletedMonth,this.seletedModel)
      .subscribe({
        next: (res) => {
          const {
            data,
            totalConsumableObj,
            averageComplianceObj,
            totalSwipesObj,
            totalWaterSavedObj,
            todayData,
            historyDataResult
          } = res;
          this.historyDataResult=historyDataResult;
          this.tw=totalSwipesObj.data.reduce((a, b) => a + b, 0);
          this.tw1=((totalConsumableObj.data.map(Number)).reduce((a, b) => a + b, 0));
          this.tw2=((averageComplianceObj.data.map(Number)).reduce((a, b) => a + b, 0))/averageComplianceObj.data.length;
         
          this.tw3=((totalWaterSavedObj.data.map(Number)).reduce((a, b) => a + b, 0));
          this.td1=todayData.water;
          this.td2=todayData.consumable;
          
          let averageComplianceObjrev=[];

          averageComplianceObj.data.forEach((item)=>{
                item>100?averageComplianceObjrev.push(100):averageComplianceObjrev.push(item);
          })
          this.AllReportDetails = data;

          if (this.AllReportDetails.some((obj) => obj.totalSwipe !== 0)) {
            this.hasReportData = true;
            this.chRef.detectChanges();
          } else {
            this.hasReportData = false;
            this.chRef.detectChanges();
            this.spinner.hide();
            return;
          }

          if (this.totalSwipeReport && this.totalSwipeReport != null)
            this.totalSwipeReport.destroy();

          if (
            this.averageComplianceReport &&
            this.averageComplianceReport != null
          )
            this.averageComplianceReport.destroy();

          if (this.totalWaterSavedReport && this.totalWaterSavedReport != null)
            this.totalWaterSavedReport.destroy();

          if (this.totalConsumableReport && this.totalConsumableReport != null)
            this.totalConsumableReport.destroy();

          this.totalSwipeReport = totalSwipesObj.data.some(
            (ele: number) => ele !== 0
          )
            ? this.fillChart(
                totalSwipesObj.labels,
                totalSwipesObj.data,
                'Date vs Total no. of Swipes',
                'totalSwipeReport'
              )
            : null;
          this.averageComplianceReport = averageComplianceObj.data.some(
            (ele) => Number.parseFloat(ele) !== 0
          )
            ? this.fillChart(
                averageComplianceObj.labels,
                averageComplianceObjrev,
                //averageComplianceObj.data,
                'Date vs Average Compliance %',
                'averageComplianceReport'
              )
            : null;

          this.totalWaterSavedReport = totalWaterSavedObj.data.some(
            (ele) => Number.parseFloat(ele) > 0
          )
            ? this.fillChart(
                totalWaterSavedObj.labels,
                totalWaterSavedObj.data,
                'Date vs Total Water saved',
                'totalWaterSavedReport'
              )
            : null;
          this.totalConsumableReport = totalConsumableObj.data.some(
            (ele) => Number.parseFloat(ele) > 0
          )
            ? this.fillChart(
                totalConsumableObj.labels,
                totalConsumableObj.data,
                'Date vs Total Consumable usage',
                'totalConsumableReport'
              )
            : 0;

          this.chRef.detectChanges();
          this.spinner.hide();
        },
        error: (error) => {
          this.spinner.hide();
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: error?.error?.message,
          });
        },
      });
    this.spinner.hide();
  }

  fillChart(
    graphLabel: any,
    graphData: any,
    legendTitle: any,
    elementId: string
  ) {
   
    const colourArray = [
      {
        elementId: 'totalSwipeReport',
        backgroundColor: 'rgba(147, 196, 125,1)',
        hoverBackgroundColor: 'rgba(147, 196, 125,0.7)',
        borderColor: 'rgba(147, 196, 125,1)',
      },
      {
        elementId: 'averageComplianceReport',
        backgroundColor: 'rgba(162, 196, 201,1)',
        hoverBackgroundColor: 'rgba(162, 196, 201,0.7)',
        borderColor: 'rgba(162, 196, 201,1)',
      },
      {
        elementId: 'totalWaterSavedReport',
        backgroundColor: 'rgba(65, 159, 247,1)',
        hoverBackgroundColor: 'rgba(65, 159, 247,0.7)',
        borderColor: 'rgba(65, 159, 247, 1)',
      },
      {
        elementId: 'totalConsumableReport',
        backgroundColor: 'rgba(180, 167, 214,1)',
        hoverBackgroundColor: 'rgba(180, 167, 214,0.7)',
        borderColor: 'rgba(180, 167, 214,1)',
      },
    ];

    return new Chart(elementId, {
      type: 'bar',
      data: {
        labels:
          graphLabel.length > 0
            ? graphLabel.map((ele) =>
                ele.includes('-') ? ele.split('-') : ele
              )
            : [],
        datasets: [
          {
            label: legendTitle,
            backgroundColor: colourArray.find(
              (obj) => obj.elementId === elementId
            ).backgroundColor,
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 50,
            minBarLength: 2,
            hoverBackgroundColor: colourArray.find(
              (obj) => obj.elementId === elementId
            ).hoverBackgroundColor,
            borderColor: colourArray.find((obj) => obj.elementId === elementId)
              .borderColor,
            data: graphData,
          },
        ],
      },
      options: {
        devicePixelRatio: 1.5,
        maintainAspectRatio: false,
        scales: {
          xAxes: {
            time: {
              unit: 'week',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              maxTicksLimit: 8,
            },
          },
          yAxes: {
            ticks: {
              maxTicksLimit: 6,
            },
            grid: {
              color: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              pointStyle: 'star',
            },
            position: 'top',
            onClick: (e, legendItem, legend) => e.native.preventDefault(),
          },
          tooltip: {
            titleMarginBottom: 10,
            titleColor: '#6e707e',
            titleFont: {
              size: 14,
            },
            backgroundColor: 'rgb(255,255,255)',
            bodyColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 1,
            padding: 15,
            displayColors: false,
            caretPadding: 10,
          },
        },
      },
    });
    this.spinner.hide();
  }

  getDocumentDefinition() {
    
    let rows = [];
    let rows1 = [];
    for (let i = 0; i < this.historyDataResult.length; i++) {
      rows1.push([
        {
          text: this.historyDataResult[i].RFID_No,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.historyDataResult[i].Name,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.historyDataResult[i].Date,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.historyDataResult[i].Time,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.historyDataResult[i].Status,
          alignment: 'center',
          fontSize: 11,
        }
      ]);
    }
    
    for (let i = 0; i < this.AllReportDetails.length; i++) {
      rows.push([
        {
          text: this.AllReportDetails[i].dateRange,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalConsumable,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalSwipe,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalCompleteSwipes,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalIncompleteSwipes,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].averageCompliance,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalWaterSaving,
          alignment: 'center',
          fontSize: 11,
        },
        
      ]);
    }

    return {
      info: {
        title: 'Micro GO - P-Monthly Report',
        author: 'Micro GO LLP',
        subject: 'P-Monthly Report',
      },
      content: [
        {
          style: 'tableExample',
          table: {
            widths: [
              '31%',
              '11.5%',
              '11.5%',
              '11.5%',
              '11.5%',
              '11.5%',
              '11.5%',
            ],
            body: [
              [
                {
                  text: [
                    'GO',
                    {
                      text: 'assure',
                      italics: true,
                    },
                    ' - SMART Hand Hygiene Station',
                  ],
                  colSpan: 7,
                  style: 'headerBig',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              [
                {
                  
                      table:{                        
                        widths:['25%','25%','25%','25%'],
                        body:[
                              [
                                { text: `Total Usage\n${this.tw} `,style:'grey',border: [false, false, false, false],margin:[0,5,0,5] },
                                { text: `Total Water Saving\n${this.tw3.toFixed(2)} L`,style:'grey', border: [false, false, false, false],margin:[0,5,0,5] },
                                { text: `Total Consumable Usage\n${this.tw1.toFixed(2)} L`,style:'grey', border: [false, false, false, false],margin:[0,5,0,5] },
                                { text: `Average Compliance (%)\n${this.tw2.toFixed(2)}`,style:'grey', border: [false, false, false, false],margin:[0,5,0,5] },
                              ],
                              [
                                { text: '',border: [false, false, false, false], },
                                { text: '' ,border: [false, false, false, false],},
                                { text: '',border: [false, false, false, false], },
                                { text: '' ,border: [false, false, false, false],},
                              ],
                              [
                                { text: `Location\n ${this.userData.Company_Name}`,border: [false, false, false, false],margin:[0,0,0,5] },
                                { text: `Water Saving Today\n${this.td1} L` ,border: [false, false, false, false],margin:[0,0,0,5]},
                                { text: `Consumable Usage Today\n${this.td2} L` ,border: [false, false, false, false],margin:[0,0,0,5]},
                                { text: `Date:${this.commonService
                                  .moment()
                                  .month(this.seletedMonth)
                                  .format('MMMM')} (${this.commonService
                                  .moment()
                                  .month(this.seletedMonth)
                                  .startOf('month')
                                  .format('DD/MM/YYYY')}-${this.commonService
                                  .moment()
                                  .month(this.seletedMonth)
                                  .endOf('month')
                                  .format('DD/MM/YYYY')})` ,border: [false, false, false, false],margin:[0,0,0,5]},
                              ],
                        ],
                      },
                      colSpan: 7,                                            
                  style: 'noborder',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              
             
              [
                {
                  text: 'Date',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 11,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: 'SOP',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                },
                {
                  text: 'Total no. of swipes',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                  margin: [0, 7, 0, 0],
                },
                {
                  text: 'Total no. of complete swipes',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                },
                {
                  text: 'Total no. of incomplete swipes',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                },
                {
                  text: 'Average compliance %',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                },
                {
                  text: 'Average Swipe / usage %',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                  margin: [0, 7, 0, 0],
                },
                
              ],
              ...rows,
             
            ],
          },
        },
       
        {
table:{
  width:['100%'],
  body:[[
    
  ]]
}
        },
        {
          table: {
            widths: ['50%', '50%'],
            body: [
              [
                {
                  image: 'totalSwipeChart',
                  fit: [250, 250],
                  alignment: 'center',
                },
                {
                  image: 'totalAverageChart',
                  fit: [250, 250],
                  alignment: 'center',
                },
              ],
            ],            
          },
        },
        {
          table: {
            widths: ['50%', '50%'],
            body: [
              [
                {
                  image: 'totalWaterSavedChart',
                  fit: [250, 250],
                  alignment: 'center',
                },
                {
                  image: 'totalConsumableChart',
                  fit: [250, 250],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        {
          table:{
            width:['100%'],
            body:[[
              
            ]]
          }
                  },
        {
          table:{                        
            widths:['20%','20%','20%','20%','20%'],
            body:[
              [
                {
                  text: 'RFID',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 11,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: 'Name',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 11,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: 'Date',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                },
                {
                  text: 'Time',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                  margin: [0, 7, 0, 0],
                },
                {
                  text: 'Status',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                } 
                               
              ],
              ...rows1
            ]
          }
  
          },
      ],
      images: {
        totalSwipeChart: this.totalSwipeChartDataURL,
        totalAverageChart: this.averageComplianceChartDataURL,
        totalWaterSavedChart: this.totalWaterSavedChartDataURL,
        totalConsumableChart: this.totalConsumableChartDataURL,
      },
      styles: {
        headerBig: {
          fontSize: 14,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
          fillColor: '#9bbb59',
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        tbldata: {
          fontSize: 10,
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        noborder:{
          fontSize: 10,
          margin: [-5, -5, -5, -5],
          alignment: 'justify',
          
        },
        grey:{
          fillColor: '#808080',
          border: [false, false, false, false]
        },
        
      },
    };
  }

  async convertHtmlToImageDataURL() {
    this.totalSwipeChartDataURL = this.totalSwipeReport.toBase64Image();
    this.averageComplianceChartDataURL =
      this.averageComplianceReport.toBase64Image();
    this.totalWaterSavedChartDataURL =
      this.seletedModel=="PMax"?this.totalWaterSavedReport.toBase64Image():null;
    this.totalConsumableChartDataURL =
      this.totalConsumableReport.toBase64Image();
  }

  getDocumentDefinition_plite() {
    
    let rows = [];
    for (let i = 0; i < this.AllReportDetails.length; i++) {
      rows.push([
        {
          text: this.AllReportDetails[i].dateRange,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalConsumable,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalSwipe,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalCompleteSwipes,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalIncompleteSwipes,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].averageCompliance,
          alignment: 'center',
          fontSize: 11,
        },
        {
          text: this.AllReportDetails[i].totalWaterSaving,
          alignment: 'center',
          fontSize: 11,
        },
        
      ]);
    }

    return {
      info: {
        title: 'Micro GO - P-Monthly Report',
        author: 'Micro GO LLP',
        subject: 'P-Monthly Report',
      },
      content: [
        {
          style: 'tableExample',
          table: {
            widths: [
              '31%',
              '11.5%',
              '11.5%',
              '11.5%',
              '11.5%',
              '11.5%',
              '11.5%',
            ],
            body: [
              [
                {
                  text: [
                    'GO',
                    {
                      text: 'assure',
                      italics: true,
                    },
                    ' - SMART Hand Hygiene Station',
                  ],
                  colSpan: 7,
                  style: 'headerBig',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              [
                {
                  
                      table:{                        
                        widths:['25%','25%','25%','25%'],
                        body:[
                              [
                                { text: `Total Usage\n${this.tw} `,style:'grey',border: [false, false, false, false],margin:[0,5,0,5] },
                                { text: `Total Water Saving\nNA`,style:'grey', border: [false, false, false, false],margin:[0,5,0,5] },
                                { text: `Total Consumable Usage\n${this.tw1.toFixed(2)} L`,style:'grey', border: [false, false, false, false],margin:[0,5,0,5] },
                                { text: `Average Compliance (%)\n${this.tw2.toFixed(2)}`,style:'grey', border: [false, false, false, false],margin:[0,5,0,5] },
                              ],
                              [
                                { text: '',border: [false, false, false, false], },
                                { text: '' ,border: [false, false, false, false],},
                                { text: '',border: [false, false, false, false], },
                                { text: '' ,border: [false, false, false, false],},
                              ],
                              [
                                { text: `Location\n ${this.userData.Company_Name}`,border: [false, false, false, false],margin:[0,0,0,5] },
                                { text: `Water Saving Today\nNA` ,border: [false, false, false, false],margin:[0,0,0,5]},
                                { text: `Consumable Usage Today\nNA` ,border: [false, false, false, false],margin:[0,0,0,5]},
                                { text: `Date:${this.commonService
                                  .moment()
                                  .month(this.seletedMonth)
                                  .format('MMMM')} (${this.commonService
                                  .moment()
                                  .month(this.seletedMonth)
                                  .startOf('month')
                                  .format('DD/MM/YYYY')}-${this.commonService
                                  .moment()
                                  .month(this.seletedMonth)
                                  .endOf('month')
                                  .format('DD/MM/YYYY')})` ,border: [false, false, false, false],margin:[0,0,0,5]},
                              ],
                        ],
                      },
                      colSpan: 7,                                            
                  style: 'noborder',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              
             
              [
                {
                  text: 'Date',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 11,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: 'SOP',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                },
                {
                  text: 'Total no. of swipes',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                  margin: [0, 7, 0, 0],
                },
                {
                  text: 'Total no. of complete swipes',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                },
                {
                  text: 'Total no. of incomplete swipes',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                },
                {
                  text: 'Average compliance %',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                },
                {
                  text: 'Average Swipe / usage %',
                  fillColor: '#b4a7d6',
                  alignment: 'center',
                  fontSize: 9,
                  margin: [0, 7, 0, 0],
                },
                
              ],
              ...rows,
            ],
          },
        },
        {
          table: {
            widths: ['50%', '50%'],
            body: [
              [
                {
                  image: 'totalSwipeChart',
                  fit: [250, 250],
                  alignment: 'center',
                },
                {
                  image: 'totalAverageChart',
                  fit: [250, 250],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ['100%'],
            alignment:"center",
            body: [
              [
                
                {
                  image: 'totalConsumableChart',
                  fit: [250, 250],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
      ],
      images: {
        totalSwipeChart: this.totalSwipeChartDataURL,
        totalAverageChart: this.averageComplianceChartDataURL,
        totalConsumableChart: this.totalConsumableChartDataURL,
      },
      styles: {
        headerBig: {
          fontSize: 14,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
          fillColor: '#9bbb59',
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        tbldata: {
          fontSize: 10,
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        noborder:{
          fontSize: 10,
          margin: [-5, -5, -5, -5],
          alignment: 'justify',
          
        },
        grey:{
          fillColor: '#808080',
          border: [false, false, false, false]
        },
        
      },
    };
  }
  async convertHtmlToImageDataURL_plite() {
    this.totalSwipeChartDataURL = this.totalSwipeReport.toBase64Image();
    this.averageComplianceChartDataURL =
      this.averageComplianceReport.toBase64Image();
    this.totalConsumableChartDataURL =
      this.totalConsumableReport.toBase64Image();
  }

  async downloadReport() {
    if(this.seletedModel=="PMax")
    {
    await this.convertHtmlToImageDataURL();
    pdfMake
      .createPdf(this.getDocumentDefinition())
      .download(
        `MicroGo - P-Monthly Report ${this.commonService
          .moment()
          .month(this.seletedMonth)
          .format('MMMM')}`
      );
    }
    else{
      await this.convertHtmlToImageDataURL_plite();
    pdfMake
      .createPdf(this.getDocumentDefinition_plite())
      .download(
        `MicroGo - P-Monthly Report ${this.commonService
          .moment()
          .month(this.seletedMonth)
          .format('MMMM')}`
      );
    }
  }
}
