import { ToastrService } from 'ngx-toastr';
import { OReportModel } from './../../models/O-ReportModel';
import { ODeviceService } from './../../services/o-device.service';
import { ReportService } from './../../services/report-service.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SignUp } from 'src/app/models/sigup';
import { AuthService } from 'src/app/auth/services/auth.service';
import {
  ReportDetails,
  ReportDetailsFinalized,
} from 'src/app/models/reportDetails';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart, registerables } from 'chart.js';
import { HasDevice } from '../../models/hasDevice';

Chart.register(...registerables);

const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-o-new-report',
  templateUrl: './o-new-report.component.html',
  styleUrls: ['./o-new-report.component.css'],
})
export class ONewReportComponent implements OnInit {
  userData: SignUp;
  totalSwipe;
  map = new Map();
  dropdownArray = [];
  dateFilteredAllUser = [];
  finalReportDetails: ReportDetailsFinalized[];
  AllReportDetails: ReportDetails[];
  maxColumn = [];
  selectedDate;
  dtOptions: any = {};
  dates = [];
  RFID_No;
  finalArray = [];
  onSubmitClick = false;
  showButton = false;
  toggleAll = false;
  selectedModel = '';
  hasReportData = true;
  complianceReport: any;
  chartDataURL: any = '';
  showDownloadButton = false;
  metadata: any = {};
  devices: HasDevice;
  deviceNames: string[];
  hasPLiteDevice = false;
  SOP: number = 8;
  deviceLocations = [];
  devicesDevice = [];
  selectedLocation: string = '';
  selectedDevice: string = '';

  oReports: OReportModel[] = [];

  finalObject: {
    Name: string;
    RFID_No: number;
    Date: string;
    Preset_HHP: number;
    Current_HHP: number;
    Time: string[];
    Status: string[];
    // Completed: number;
    // Incompleted: number;
    Compliance: string;
  };

  columns = [
    { key: 'index', value: 'Sr No.' },
    { key: 'Date', value: 'Date' },
    { key: 'Device_Name', value: 'Device Name' },
    { key: 'Location', value: 'Bed No' },
    { key: 'Total_Opportunity', value: 'Total Opportunity' },
    { key: 'Total_Complete_Usage', value: 'Completed Usage' },
    { key: 'Total_Incomplete_Usage', value: 'Incompleted Usage' },
    { key: 'Total_Usage', value: 'Total Usage' },
    { key: 'Avg_Compliance', value: 'Avg Compilance %' },
  ];

  headers = ['Name', 'Date', 'Total', 'Compliance'];
  // headers = ['Name', 'Date', 'Total', 'Complete', 'Incomplete', 'Compliance'];

  constructor(
    private oDeviceService: ODeviceService,
    // public reportService: ReportService,
    private authService: AuthService,
    private chRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private toastrService: ToastrService
  ) {
    this.userData = new SignUp();
    this.devices = new HasDevice();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.resetVariables();
    this.userData = JSON.parse(sessionStorage.getItem(this.authService.USER));
    this.devices = JSON.parse(sessionStorage.getItem(this.authService.DEVICES));
    this.deviceNames = this.devices.deviceNames;
    if (
      this.deviceNames.includes('P Lite') &&
      !this.deviceNames.includes('P Max')
    )
      this.hasPLiteDevice = true;
    this.getTotalDates();
    this.getLocationAndDevices();
    this.chRef.detectChanges();
    this.spinner.hide();
  }

  resetVariables() {
    this.maxColumn = [];
    this.onSubmitClick = false;
    this.selectedDate = '';
    this.selectedModel = '';
    this.showButton = false;
    if (this.finalReportDetails != undefined) {
      while (this.finalReportDetails.length > 0) {
        this.finalReportDetails.pop();
      }
    }
  }

  getTotalDates() {
    this.oDeviceService
      .getTotalDates({ SC_Name: 'sc_' + this.userData.UId })
      .subscribe((res) => {
        this.dates = res.Dates.sort();
        this.dates = this.dates.reverse();
      });
  }

  getLocationAndDevices() {
    this.oDeviceService
      .getLocationAndDevices(this.userData.UId)
      .subscribe((res) => {
        this.devicesDevice = res.deviceNames;
        this.deviceLocations = res.location;
      });
  }

  async getReportList() {
    if(this.complianceReport){this.complianceReport.destroy();}
    this.spinner.show();
    this.showButton = false;
    if (this.onSubmitClick)
      this.commonService.destroyDatatbles('dataTableAllUser');
    // this.chRef.detectChanges();
    const requestBody = {
      SC_Name: `sc_${this.userData.UId}`,
      Date: this.selectedDate,
      Model: this.selectedModel,
      Location: this.selectedLocation,
      DeviceName: this.selectedDevice,
    };
    this.oDeviceService.getReportsByFilter(requestBody).subscribe({
      next: async (res) => {
        this.oReports = [];
        this.oReports = res.data;

        // this.commonService.destroyDatatbles('dataTableAllUser');

        this.onSubmitClick = true;

        if (this.oReports.length) {
          this.hasReportData = true;
          this.showDownloadButton = true;

          let uniqueDevices = [
            ...new Set(this.oReports.map(({ Device_MAC }) => Device_MAC)),
          ];

          let graphLabels = [],
            graphData = [],
            label = null,
            Compliance = null;

          for (let i = 0; i < uniqueDevices.length; i++) {
            label = this.oReports.find(({ Device_MAC }) => {
              return Device_MAC === uniqueDevices[i];
            });
            label = label['Device_Name'];

           /*  let ComplianceArray = this.oReports
              .filter(({ Device_MAC }) => Device_MAC == uniqueDevices[i])
              .map((item) => item.Compliance); */

              let ComplianceArray = this.oReports
              .filter(({ Device_MAC }) => Device_MAC == uniqueDevices[i])
              .map((item) => this.percent((item.Total_Complete_Usage + item.Total_Incomplete_Usage)/item.Total_Opportunity));

            Compliance =
              ComplianceArray.length > 1
                ? ComplianceArray.reduce(
                    (prev, next) => Number(prev) + Number(next)
                  ).toFixed()
                : ComplianceArray[0];

                Compliance=ComplianceArray.length>1?Compliance/ComplianceArray.length:Compliance;

            /* Compliance =
              this.oReports.length > 1
                ? this.oReports
                    .filter(({ Device_MAC }) => Device_MAC == uniqueDevices[i])
                    .map((item) => item.Compliance)
                    .reduce((prev, next) => Number(prev) + Number(next))
                    .toFixed()
                : this.oReports
                    .filter(({ Device_MAC }) => Device_MAC == uniqueDevices[i])
                    .map((item) => item.Compliance)[0]; */

            graphLabels.push(label);
            graphData.push(Compliance);
          }

          /* if (this.complianceReport && this.complianceReport != null)
            this.complianceReport.destroy(); */
          this.fillComplianceChart(graphLabels, graphData);
          this.chRef.detectChanges();
          this.spinner.hide();
        } else {
          this.hasReportData = false;
        }
        this.chRef.detectChanges();
        this.loadDatatbles();
        this.spinner.hide();
      },
      error: (error) => {
        console.log('err :>> ', error);
        this.oReports = [];
        this.commonService.destroyDatatbles('dataTableAllUser');
        this.showDownloadButton = false;
        this.spinner.hide();
        this.toastrService.warning('Record not found!', 'Oops!');
      },
    });
    // this.spinner.hide();
  }

  getDocumentDefinition1() {
    let columns = [...this.headers],
      rows = [];

    let pushIndex = columns.length - 2;
    columns.splice(pushIndex, 0, 'Usage (Incomplete)');
    columns.splice(pushIndex, 0, 'Usage (Complete)');

    let finalColumns = columns.map((value) => {
      return { text: value, alignment: 'center', style: 'header' };
    });

    let goAssureHeader = [];
    goAssureHeader.push({
      text: [
        'GO',
        {
          text: 'assure',
          italics: true,
        },
        ' - SMART Hand Hygiene Station',
      ],
      colSpan: columns.length,
      style: 'headerBig',
      alignment: 'center',
    });

    let footerData = [];
    footerData.push({
      columns: [
        {
          text: [
            { text: '   ', background: '#ff0000' },
            ' : Compliance (%) less than 25',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#0000ff' },
            ' : Compliance (%) between 25 to 80',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#00ff00' },
            ' : Compliance (%) greater than equal to 80',
          ],
          fontSize: 8,
        },
      ],
      alignment: 'center',
    });

    for (let i = 1; i < columns.length; i++) {
      goAssureHeader.push({ text: '' });
    }

    let width = (100 / finalColumns.length).toFixed(2),
      widths = [];

    for (let i = 0; i < finalColumns.length; i++) {
      widths.push(`${width}%`);
    }

    let numbers = this.headers.slice(2, this.headers.length - 2);

    let singleRow = [],
      singleRecord = null,
      background = '';

    for (let i = 0; i < this.finalReportDetails.length; i++) {
      singleRecord = this.finalReportDetails[i];
      singleRow = [];
      singleRow.push({
        text: singleRecord.Name,
        fontSize: 5,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Date,
        fontSize: 5,
        alignment: 'center',
      });

      for (let j = 0; j < numbers.length; j++) {
        background =
          singleRecord.Status[j] == 'complete' ||
          singleRecord.Status[j] == 'completed' ||
          singleRecord.Status[j] == 'Complete' ||
          singleRecord.Status[j] == 'Completed'
            ? '#b3ffb3'
            : '#ff8080';

        singleRow.push({
          stack: [
            { text: singleRecord.Time[j], alignment: 'center' },
            { text: singleRecord.Status[j], background, alignment: 'center' },
          ],
          fontSize: 5,
        });
      }

      let completed = singleRecord.Status.filter(
        (ele) =>
          ele == 'complete' ||
          ele == 'completed' ||
          ele == 'Complete' ||
          ele == 'Completed'
      ).length;

      let Incompleted = singleRecord.Status.filter(
        (ele) =>
          ele == 'incomplete' ||
          ele == 'incompleted' ||
          ele == 'Incomplete' ||
          ele == 'Incompleted'
      ).length;

      singleRow.push({ text: completed, fontSize: 5, alignment: 'center' });
      singleRow.push({ text: Incompleted, fontSize: 5, alignment: 'center' });
      singleRow.push({
        text: completed + Incompleted,
        fontSize: 5,
        alignment: 'center',
      });
      singleRow.push({
        text: singleRecord.Compliance,
        fontSize: 5,
        alignment: 'center',
      });

      rows.push(singleRow);
    }

    let {
      SOP,
      averageCompliance,
      consumableToday,
      date,
      location,
      totalCompletedUsageToday,
      totalConsumable,
      totalIncompletedUsageToday,
      totalUsage,
      totalUsageToday,
      totalWaterSaving,
      waterSavingToday,
    } = this.metadata;

    location = `${this.userData.Company_Name}, ${this.userData.Location}`;
    // SOP = 'Minimum 4 times daily Usage';
    SOP = `${this.SOP} times / day`;

    averageCompliance = this.finalReportDetails
      .map((item) => Number.parseInt(item.Compliance))
      .reduce((prev, next) => Number(prev) + Number(next))
      .toFixed();

    averageCompliance = (
      Number(averageCompliance) / this.finalReportDetails.length
    ).toFixed();

    // let totalColumn = 22;
    let divideBy = 4;
    let extraCount = columns.length % 4;
    let columnsLength = columns.length;
    let baseColspan = Number.parseInt(
      (columnsLength / divideBy).toString(),
      10
    );
    let colspanData = [];

    function fillColspanData(extraCount) {
      switch (extraCount) {
        case 0:
          colspanData = Array(4).fill(baseColspan);
          break;
        case 1:
          colspanData = [...Array(3).fill(baseColspan), baseColspan + 1];
          break;
        case 2:
          colspanData = [
            baseColspan + 1,
            ...Array(2).fill(baseColspan),
            baseColspan + 1,
          ];
          break;
        case 3:
          colspanData = [
            ...Array(2).fill(baseColspan + 1),
            baseColspan,
            baseColspan + 1,
          ];
          break;
        default:
          colspanData = Array(4).fill(baseColspan);
      }
    }

    fillColspanData(extraCount);
    colspanData;

    let firstRow = [],
      secondRow = [],
      thirdRow = [];

    let dataaa = [1];
    for (let i = 0; i < colspanData.length - 1; i++) {
      const lastIndex = dataaa.length - 1;
      dataaa.push(dataaa[lastIndex] + colspanData[i]);
    }

    let firstRowData = [
      {
        text: 'Total usage:',
        value: totalUsage,
      },
      {
        text: 'Total water saving:',
        value: this.hasPLiteDevice
          ? `Only applicable on P MAX`
          : `${totalWaterSaving} L`,
      },
      {
        text: 'Total consumable usage:',
        value: `${totalConsumable} L`,
      },
      {
        text: 'Average compliance (%):',
        value: averageCompliance,
      },
    ];

    let secondRowData = [
      {
        text: 'Company/Location:',
        value: location,
      },
      {
        text: 'Water saving today:',
        value: this.hasPLiteDevice
          ? `Only applicable on P MAX`
          : `${waterSavingToday} L`,
      },
      {
        text: 'Consumable usage today:',
        value: `${consumableToday} L`,
      },
      {
        text: 'Date:',
        value: date,
      },
    ];

    let thirdRowData = [
      {
        text: 'Total usage today:',
        value: totalUsageToday,
      },
      {
        text: 'Total complete usage today:',
        value: totalCompletedUsageToday,
      },
      {
        text: 'Total incomplete usage today:',
        value: totalIncompletedUsageToday,
      },
      {
        text: 'SOP definition:',
        value: SOP,
      },
    ];

    let count = 0;
    for (let i = 1; i <= columns.length; i++) {
      if (dataaa.includes(i)) {
        let border =
          count === 0
            ? [true, false, false, false]
            : count === 3
            ? [false, false, true, false]
            : [false, false, false, false];
        firstRow.push({
          text: `${firstRowData[count].text} ${firstRowData[count].value}`,
          colSpan: colspanData[count],
          alignment: 'center',
          fontSize: 8,
          border,
        });
        count++;
      } else {
        firstRow.push({ text: '' });
      }
    }

    count = 0;
    for (let i = 1; i <= columns.length; i++) {
      if (dataaa.includes(i)) {
        let border =
          count === 0
            ? [true, false, false, false]
            : count === 3
            ? [false, false, true, false]
            : [false, false, false, false];
        secondRow.push({
          text: `${secondRowData[count].text} ${secondRowData[count].value}`,
          colSpan: colspanData[count],
          alignment: 'center',
          fontSize: 8,
          border,
        });
        count++;
      } else {
        secondRow.push({ text: '' });
      }
    }

    count = 0;

    for (let i = 1; i <= columns.length; i++) {
      if (dataaa.includes(i)) {
        let border =
          count === 0
            ? [true, false, false, false]
            : count === 3
            ? [false, false, true, false]
            : [false, false, false, false];
        thirdRow.push({
          text: `${thirdRowData[count].text} ${thirdRowData[count].value}`,
          colSpan: colspanData[count],
          alignment: 'center',
          fontSize: 8,
          border,
        });
        count++;
      } else {
        thirdRow.push({ text: '' });
      }
    }

    return {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      info: {
        title: 'Micro GO - P-Report',
        author: 'Micro GO LLP',
        subject: 'P-Report',
      },
      content: [
        {
          style: 'headerTable',
          table: {
            widths: ['35%', '35%', '34%', '0%', '0%', '0%'],
            body: [
              [
                {},
                {
                  text: 'MicroGO | P MAX / P Lite - Report',
                  style: 'headerBig',
                  alignment: 'center',
                  margin: [0, 25, 0, 0],
                },
                {},
              ],
            ],
          },
          layout: 'noBorders',
        },
        { text: '', margin: [0, 20, 0, 0] },
        {
          style: 'tableExample',
          table: {
            widths: widths,
            body: [
              goAssureHeader,
              firstRow,
              secondRow,
              thirdRow,
              finalColumns,
              ...rows,
            ],
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex >= 2 && rowIndex % 2 === 0 ? '#f0eded' : null;
            },
          },
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  image: 'chart',
                  fit: [500, 500],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ['*'],
            body: [footerData],
          },
        },
        {
          text: '**only Set SOP data will be visible , above SOP usage count can be checked in the total count . For details check "View all reports" in the website.',
          fontSize: 8,
          alignment: 'center',
          margin: [0, 0, 0, 3],
        },
        {
          text: 'Please note: Overall Compliance % on the dashboard  is calculated as complete vs Incomplete cycle whereas on the daily report, the compliance % is reported as defined by the SOP required by you',
          fontSize: 8,
          alignment: 'center',
        },
      ],
      images: {
        chart: this.chartDataURL,
      },
      styles: {
        headerBig: {
          fontSize: 14,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        header: {
          fontSize: 6,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        headerTable: {
          alignment: 'justify',
        },
      },
    };
  }

  getDocumentDefinition() {
    let columns = this.columns.map(({ value }) => {
      return { text: value, alignment: 'center', style: 'header' };
    });

    let rows = [];
    let singleRow = [];
    //let roReports=
    if (this.oReports.length > 0) {
      for (let i = 0; i < this.oReports.length; i++) {
        singleRow = [];
        let obj:any = this.oReports[i];
        obj.Total_Usage=this.oReports[i].Total_Complete_Usage+this.oReports[i].Total_Incomplete_Usage;
        obj.Avg_Compliance=this.percent((this.oReports[i].Total_Complete_Usage+this.oReports[i].Total_Incomplete_Usage)/this.oReports[i].Total_Opportunity);
  
        this.columns.forEach(({ key }) => {
          key !== 'index'
            ? singleRow.push({
                text: obj[key],
                alignment: key === 'Compliance' ? 'right' : 'center',
                fontSize: 10,
              })
            : singleRow.push({
                text: i + 1,
                alignment: 'center',
                fontSize: 10,
              });
        });
        rows.push(singleRow);
      }
    }

   // let startDate = new Date(this.startDate).toISOString().split('T')[0];
    //let endDate = new Date(this.endDate).toISOString().split('T')[0];

    let averageCompliance =
      this.oReports.length > 1
        ? this.oReports
            .map((item) => this.percent((item.Total_Complete_Usage + item.Total_Incomplete_Usage)/item.Total_Opportunity))
            .reduce((prev, next) => Number(prev) + Number(next))
            .toFixed()
        : this.oReports[0].Compliance;

    averageCompliance = (
      Number(averageCompliance) / this.oReports.length
    ).toFixed();

    let footerData = [];
    footerData.push({
      columns: [
        {
          text: [
            { text: '   ', background: '#ff0000' },
            ' : Compliance (%) less than 25',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#0000ff' },
            ' : Compliance (%) between 25 to 80',
          ],
          fontSize: 8,
        },
        {
          text: [
            { text: '   ', background: '#00ff00' },
            ' : Compliance (%) greater than equal to 80',
          ],
          fontSize: 8,
        },
      ],
      alignment: 'center',
    });

    return {
      info: {
        title: 'MicroGO - O-Report',
        author: 'MicroGO LLP',
        subject: 'O-Report',
      },
      content: [
        {
          style: 'headerTable',
          table: {
            widths: ['35%', '35%', '34%', '0%', '0%', '0%'],
            body: [
              [
                {},
                {
                  text: 'MicroGo | O - Report',
                  style: 'headerBig',
                  alignment: 'center',
                  margin: [0, 25, 0, 0],
                },
                {},
              ],
            ],
          },
          layout: 'noBorders',
        },
        { text: '', margin: [0, 20, 0, 0] },
        {
          style: 'tableExample',
          table: {
            body: [
              [
                {
                  text: [
                    'GO',
                    {
                      text: 'assure',
                      italics: true,
                    },
                    ' - SMART Hand Hygiene Station',
                  ],
                  colSpan: 9,
                  style: 'headerBig',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              [
                {
                  text: `Date : ${this.selectedDate}`,
                  colSpan: 5,
                  style: 'headerBig',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                {
                  text: `Average Compliance : ${averageCompliance}%`,
                  colSpan: 4,
                  style: 'headerBig',
                  alignment: 'center',
                },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              columns,
              ...rows,
            ],
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex >= 2 && rowIndex % 2 === 0 ? '#ECEAEA' : null;
            },
          },
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  image: 'chart',
                  fit: [500, 500],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ['*'],
            body: [footerData],
          },
        },
      ],
      images: {
        chart: this.chartDataURL,
      },
      styles: {
        headerBig: {
          fontSize: 14,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'justify',
          margin: [0, 5, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          alignment: 'justify',
        },
        headerTable: {
          alignment: 'justify',
        },
      },
    };
  }

  async convertHtmlToImageDataURL() {
    this.chartDataURL = this.complianceReport.toBase64Image();
  }

  async openPdf() {
    // const documentDefinition = this.getDocumentDefinition();
    // let dom = JSON.stringify(documentDefinition);
    // dom = JSON.parse(dom);

    await this.convertHtmlToImageDataURL();
    pdfMake.createPdf(this.getDocumentDefinition()).open();
  }

  async downloadReport() {
    await this.convertHtmlToImageDataURL();
    pdfMake
      .createPdf(this.getDocumentDefinition())
      .download(`MicroGo - P-Report ${this.selectedDate}`);
  }

  poolColors(graphData: []) {
    let colours = [];
    let colors = {
      blue: 'rgba(0,0,255,1)',
      green: 'rgba(0, 255, 0,1)',
      red: 'rgba(255,0,0,1)',
    };
    for (let i = 0; i < graphData.length; i++) {
      if (Number(graphData[i]) >= 80) {
        colours.push(colors.green);
      } else if (Number(graphData[i]) > 25) {
        colours.push(colors.blue);
      } else {
        colours.push(colors.red);
      }
    }
    return colours;
  }

  fillComplianceChart(graphLabel, graphData) {
    this.complianceReport = new Chart('complianceReport', {
      type: 'bar',
      data: {
        labels: graphLabel,
        datasets: [
          {
            label: 'Compliance',
            backgroundColor: this.poolColors(graphData),
            barPercentage: 0.5,
            barThickness: graphData.length > 10 ? 10 : 30,
            maxBarThickness: graphData.length > 10 ? 30 : 50,
            minBarLength: 2,
            hoverBackgroundColor: 'rgba(0,0,255,0.7)',
            borderColor: 'rgba(0,0,255,1)',
            data: graphData,
          },
        ],
      },
      options: {
        devicePixelRatio: 1.5,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          xAxes: {
            title: {
              text: 'Devices',
              display: true,
            },
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              // maxTicksLimit: 8,
            },
          },
          yAxes: {
            title: {
              text: 'Compliance %',
              display: true,
            },
            ticks: {
              minRotation: 0,
              maxRotation: 100,
              // maxTicksLimit: 6,
              padding: 10,
              // Include a dollar sign in the ticks
              // callback: function(value, index, values) {
              //     return '$' + number_format(value);
              // }
            },
            grid: {
              color: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            titleMarginBottom: 10,
            titleColor: '#6e707e',
            titleFont: {
              size: 14,
            },
            backgroundColor: 'rgb(255,255,255)',
            bodyColor: '#858796',
            borderColor: '#dddfeb',
            borderWidth: 1,
            padding: 15,
            displayColors: false,
            caretPadding: 10,
            /* callbacks: {
                  label: function (context) {
                    return `${Number(context.parsed.y)}`;
                  },
                }, */
          },
        },
      },
    });
  }

  loadDatatbles() {
    this.commonService.loadReportDatatables('dataTableAllUser');
  }

  onOptionsSelected(event) {
    if (event.target.value != 0) {
      this.selectedDate = event.target.value;
      this.showButton = this.selectedModel && this.selectedDate ? true : false;
    }
  }

  onModelSelected(event) {
    if (event.target.value != 'All') {
      this.selectedModel = event.target.value;
    } else {
      this.selectedModel = 'All';
    }
    this.showButton = this.selectedModel && this.selectedDate ? true : false;
  }

  onLocationSelected(event) {
    if (event.target.value != 'none') {
      this.selectedLocation = event.target.value;
    } else {
      this.selectedLocation = '';
    }
    this.showButton = this.selectedModel && this.selectedDate ? true : false;
  }

  onDeviceSelected(event) {
    if (event.target.value != 'none') {
      this.selectedDevice = event.target.value;
    } else {
      this.selectedDevice = '';
    }
    this.showButton = this.selectedModel && this.selectedDate ? true : false;
  }

  percent(x:any)
  {
    x=x*100;
    if(x>=100)
    {
        x=100;
    }
    return x.toFixed(0);
  }
}
